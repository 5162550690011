/* eslint-disable no-empty */
declare var window: any;

import useConVisit from '@/hooks/useConVisit';

export class Utils {
	// 获取URL里指定参数名的值
	public static getQueryString(url: string, paramName: string): string {
		const reg = new RegExp('(^|&)' + paramName + '=([^&]*)(&|$)');
		const pos: number = url.indexOf('?');
		if (pos === -1) {
			return '';
		}

		const result = url.substr(pos + 1).match(reg);
		if (result != null && result.length > 1) {
			return result[2];
		}
		return '';
	}

	// 生成随机数
	public static getRandomNum(needNum: number, allNum: number) {
		let randomList: any = []; //生成的随机数组
		while (randomList.length < needNum) {
			let num = Math.floor(Math.random() * allNum);
			if (randomList.indexOf(num) == -1) {
				randomList.push(num);
			}
		}

		return randomList.join('');
	}

	public static supportsStorage(): boolean {
		try {
			return 'Storage' in window && window['Storage'] !== null;
		} catch (e) {
			return false;
		}
	}

	public static setLocalData(name: string, value: string) {
		if (Utils.supportsStorage()) {
			window.localStorage.setItem(name, value);
		} else {
			alert('温馨提示：您的设备不支持本地存储！');
		}
	}

	public static getLocalData(name: string): string {
		return window.localStorage.getItem(name);
	}

	public static removeLocalData(name: string) {
		return window.localStorage.removeItem(name);
	}

	public static setSessionData(name: string, value: string) {
		if (Utils.supportsStorage()) {
			window.sessionStorage.setItem(name, value);
		} else {
			alert('温馨提示：您的设备不支持本地存储！');
		}
	}

	public static getSessionData(name: string): string {
		return window.sessionStorage.getItem(name);
	}

	public static removeSessionData(name: string) {
		window.sessionStorage.removeItem(name);
	}

	public static oneOf(ele: string, arr: string[]): boolean {
		return arr.indexOf(ele) >= 0;
	}

	public static parseDatetime(str: string): Date | null {
		if (!str) {
			return null;
		}
		str = str.replace(/-/g, '/');
		const d = new Date(str);
		return d;
	}

	public static validDateRange(v1: string, v2: string, maxDays: number): boolean {
		const t1 = Utils.parseDatetime(v1);
		const t2 = Utils.parseDatetime(v2);
		if (t1 === null || t2 === null) {
			return false;
		}
		return (t2.getTime() - t1.getTime()) / 86400000 < maxDays;
	}

	public static parseTime(time: string | number | Date, cFormat: string) {
		if (arguments.length === 0) {
			return null;
		}
		const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
		let date;
		if (typeof time === 'object') {
			date = time;
		} else {
			if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
				time = parseInt(time);
			}
			if (typeof time === 'number' && time.toString().length === 10) {
				time = time * 1000;
			}
			date = new Date(time);
		}
		const formatObj: any = {
			y: date.getFullYear(),
			m: date.getMonth() + 1,
			d: date.getDate(),
			h: date.getHours(),
			i: date.getMinutes(),
			s: date.getSeconds(),
			a: date.getDay(),
		};
		const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result: string | any[], key: string) => {
			let value = formatObj[key];
			// Note: getDay() returns 0 on Sunday
			if (key === 'a') {
				return ['日', '一', '二', '三', '四', '五', '六'][value];
			}
			if (result.length > 0 && value < 10) {
				value = '0' + value;
			}
			return value || 0;
		});
		return timeStr;
	}

	public static formatDatetime(str: string | Date): string {
		if (!str) {
			return '';
		}
		let theTime: Date;
		if (typeof str === 'string') {
			str = str.replace(/\-/g, '/');
			theTime = new Date(str);
		} else {
			theTime = str;
		}
		const y = theTime.getFullYear(); // 获取完整的年份(4位,1970-????)
		const m = theTime.getMonth() + 1; // 获取当前月份(0-11,0代表1月)
		const d = theTime.getDate(); // 获取当前日(1-31)
		const h = theTime.getHours();
		const min = theTime.getMinutes();
		const second = theTime.getSeconds();

		return (
			y +
			'-' +
			(m < 10 ? '0' + m : m) +
			'-' +
			(d < 10 ? '0' + d : d) +
			' ' +
			(h < 10 ? '0' + h : h) +
			':' +
			(min < 10 ? '0' + min : min) +
			':' +
			(second < 10 ? '0' + second : second)
		);
	}

	public static formatDate(str: string | Date): string {
		if (!str) {
			return '';
		}
		let theTime: Date;
		if (typeof str === 'string') {
			str = str.replace(/\-/g, '/');
			theTime = new Date(str);
		} else {
			theTime = str;
		}
		const y = theTime.getFullYear(); // 获取完整的年份(4位,1970-????)
		const m = theTime.getMonth() + 1; // 获取当前月份(0-11,0代表1月)
		const d = theTime.getDate(); // 获取当前日(1-31)

		return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d);
	}

	// 将时间转换为2006-01的月份格式
	public static formatToMonth(s: string | Date): string {
		if (!s) {
			return '';
		}

		let date: Date;
		if (typeof s === 'string') {
			s = s.replace(/\-/g, '/');
			date = new Date(s);
		} else {
			date = s;
		}

		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		return `${year}-${Utils.to2digit(month)}`;
	}

	public static dateFormatToNumber(date: any): number {
		let newDate: number;
		newDate = parseInt(date.replace(/-/g, ''), 0);
		return newDate;
	}

	public static dateFormatToString(date: any): string {
		let newDate: string;
		let time = date.toString();
		newDate = time.slice(0, 4) + '-' + time.slice(4, 6) + '-' + time.slice(6);
		return newDate;
	}

	public static last30date(): Date {
		let d = new Date();
		let m = new Date(d.getTime() - 30 * 86400 * 1000);
		return m;
	}

	public static last7date(): Date {
		let d = new Date();
		let m = new Date(d.getTime() - 7 * 86400 * 1000);
		return m;
	}

	public static yesterday(): Date {
		let d = new Date();
		let m = new Date(d.getTime() - 1 * 86400 * 1000);
		return m;
	}

	public static setday(time: any, num: any): string {
		let d = new Date(time);
		let m = Utils.formatDate(new Date(d.getTime() + num * 86400 * 1000));
		return m;
	}

	public static today(): Date {
		return new Date();
	}

	public static to2digit(n: number) {
		return ('00' + n).slice(-2);
	}

	public static formatToyuan(n: number) {
		n = n / 100;
		let str = '';
		if (n > 10000) {
			str = `${(n / 10000).toFixed(2)}W`;
		} else {
			str = n.toFixed(2);
		}

		return str;
	}

	// 获取月份时间戳   time:需转换时间   num:需转换时间前后推移月份  day:那一天
	public static nowGetMonth(time: any, num: number, day: number) {
		let date = new Date(time);
		let nowYear = date.getFullYear();
		let nowMonth = date.getMonth();
		let newDay = num == 0 ? day : new Date(nowYear, nowMonth + num, 0).getDate();
		if (num == 0) return new Date(nowYear, nowMonth, day).getTime();
		if (num == 1) return new Date(nowYear, nowMonth, newDay).getTime();
	}

	// 合并去重数组
	public static mergeArray(arr1: any, arr2: any) {
		for (let i = 0; i < arr1.length; i++) {
			for (let j = 0; j < arr2.length; j++) {
				if (arr1[i] === arr2[j]) {
					arr1.splice(i, 1);
				}
			}
		}
		for (let i = 0; i < arr2.length; i++) {
			arr1.push(arr2[i]);
		}
		return arr1;
	}

	// 上移
	public static moveUp(arr: any, index: number) {
		if (index === 0) {
			return;
		}
		Utils.moveArray(arr, index, index - 1);
	}

	public static moveDown(arr: any, index: number) {
		if (index === arr.length - 1) {
			return;
		}
		Utils.moveArray(arr, index, index + 1);
	}

	// 交换位置
	public static moveArray(arr: any, index1: number, index2: number) {
		arr[index1] = arr.splice(index2, 1, arr[index1])[0];
		return arr;
	}

	public static deepCopy(obj: any): any {
		let newObj: any;
		try {
			newObj = obj.push ? [] : {};
		} catch (error) {
			newObj = {};
		}
		for (let attr in obj) {
			if (obj[attr] && typeof obj[attr] === 'object') {
				newObj[attr] = Utils.deepCopy(obj[attr]);
			} else {
				newObj[attr] = obj[attr];
			}
		}
		return newObj;
	}

	public static genders(): Array<any> {
		return [
			{ value: 2, label: '男' },
			{ value: 1, label: '女' },
		];
	}

	public static weeks(): Array<any> {
		return [
			{ value: 0, label: '星期日' },
			{ value: 1, label: '星期一' },
			{ value: 2, label: '星期二' },
			{ value: 3, label: '星期三' },
			{ value: 4, label: '星期四' },
			{ value: 5, label: '星期五' },
			{ value: 6, label: '星期六' },
		];
	}

	public static months(): Array<any> {
		return [
			{ value: 1, label: '一月' },
			{ value: 2, label: '二月' },
			{ value: 3, label: '三月' },
			{ value: 4, label: '四月' },
			{ value: 5, label: '五月' },
			{ value: 6, label: '六月' },
			{ value: 7, label: '七月' },
			{ value: 8, label: '八月' },
			{ value: 9, label: '九月' },
			{ value: 10, label: '十月' },
			{ value: 11, label: '十一月' },
			{ value: 12, label: '十二月' },
		];
	}

	public static arenaTypes(): Array<any> {
		return [
			{ value: 1, label: '篮球' },
			{ value: 2, label: '足球' },
			{ value: 3, label: '羽毛球' },
			{ value: 4, label: '网球' },
			{ value: 5, label: '游泳店' },
			{ value: 6, label: '乒乓球' },
			{ value: 7, label: '排球' },
			{ value: 8, label: '蹦床' },
			{ value: 9, label: '射箭' },
			{ value: 10, label: '多功能' },
			{ value: 11, label: '拓展' },
			{ value: 12, label: '滑雪' },
			{ value: 13, label: '健身房' },
			{ value: 14, label: '高尔夫' },
			{ value: 15, label: '滑板' },
			{ value: 16, label: '攀岩' },
			{ value: 17, label: '飞盘' },
			{ value: 18, label: '壁球' },
			{ value: 19, label: '桌球' },
			{ value: 20, label: '体能' },
			{ value: 21, label: '匹克球' },
			{ value: 22, label: '冰壶' },
			{ value: 23, label: '滑冰' },
			{ value: 24, label: '健身' },
		];
	}

	public static weekNames(weeks: number[]): string {
		if (weeks.length == 7) {
			return '周一 ~ 周日';
		}
		var strs: string[] = [];
		Utils.weeks().forEach((w: any) => {
			if (weeks.includes(w.value)) {
				strs.push(w.label);
			}
		});
		return strs.join('、');
	}

	// 乘法
	public static AccMul(arg1 = 0, arg2 = 1) {
		arg1 = Number(arg1);
		arg2 = Number(arg2);
		var m = 0;
		var s1 = arg1.toString();
		var s2 = arg2.toString();
		try {
			m += s1.split('.')[1].length;
		} catch (e) {}
		try {
			m += s2.split('.')[1].length;
		} catch (e) {}
		return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
	}
	// 除法
	public static AccDiv(arg1 = 0, arg2 = 1) {
		arg1 = Number(arg1);
		arg2 = Number(arg2);
		var t1 = 0;
		var t2 = 0;
		var r1;
		var r2;
		try {
			t1 = arg1.toString().split('.')[1].length;
		} catch (e) {}
		try {
			t2 = arg2.toString().split('.')[1].length;
		} catch (e) {}
		r1 = Number(arg1.toString().replace('.', ''));
		r2 = Number(arg2.toString().replace('.', ''));
		return Math.round(Utils.AccMul(r1 / r2, Math.pow(10, t2 - t1)));
	}
	// 加法
	public static AccAdd(arg1 = 0, arg2 = 0) {
		arg1 = Number(arg1);
		arg2 = Number(arg2);
		var r1, r2, m;
		try {
			r1 = arg1.toString().split('.')[1].length;
		} catch (e) {
			r1 = 0;
		}
		try {
			r2 = arg2.toString().split('.')[1].length;
		} catch (e) {
			r2 = 0;
		}
		m = Math.pow(10, Math.max(r1, r2));
		return (arg1 * m + arg2 * m) / m;
	}
	// 减法
	public static Subtr(arg1 = 0, arg2 = 0) {
		arg1 = Number(arg1);
		arg2 = Number(arg2);
		var r1, r2, m;
		try {
			r1 = arg1.toString().split('.')[1].length;
		} catch (e) {
			r1 = 0;
		}
		try {
			r2 = arg2.toString().split('.')[1].length;
		} catch (e) {
			r2 = 0;
		}
		m = Math.pow(10, Math.max(r1, r2));
		return (arg1 * m - arg2 * m) / m;
	}

	// 身份证手机号加密
	public static plusXing(str: string, frontLen: number, endLen: number) {
		let len = str.length - frontLen - endLen;
		let xing = '';
		for (let i = 0; i < len; i++) {
			xing += '*';
		}
		return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
	}

	// 根据权限进行手机号加密
	public static phoneXing(phone: string) {
		// TODO
		return phone;

		if (!phone) return;
		if (useConVisit('phone', 'users')) return phone;

		// 进行加星
		return Utils.plusXing(phone, 3, 4);
	}

	// 防抖
	static debounce(fn: any, delay = 200) {
		let timer: any;
		return function (...args: any) {
			if (timer) {
				clearTimeout(timer);
			}
			timer = setTimeout(() => {
				fn.apply(fn, args);
				timer = null;
			}, delay);
		};
	}

	// 节流
	static throttle(fn: any, delay = 200) {
		let timer: any;
		return function (...args: any) {
			if (timer) return;
			timer = setTimeout(() => {
				fn.apply(fn, args);
				timer = null;
			}, delay);
		};
	}

	// 获取指定颜色的低透明度
	static getColorOpacity(color, opacity) {
		var theColor = color.toLowerCase();
		//十六进制颜色值的正则表达式
		var r = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
		// 如果是16进制颜色
		if (theColor && r.test(theColor)) {
			if (theColor.length === 4) {
				var sColorNew = '#';
				for (var i = 1; i < 4; i += 1) {
					sColorNew += theColor.slice(i, i + 1).concat(theColor.slice(i, i + 1));
				}
				theColor = sColorNew;
			}
			//处理六位的颜色值
			var sColorChange = [];
			// eslint-disable-next-line @typescript-eslint/no-redeclare
			for (var i = 1; i < 7; i += 2) {
				sColorChange.push(parseInt('0x' + theColor.slice(i, i + 2)));
			}
			return 'rgba(' + sColorChange.join(',') + ',' + opacity + ')';
		}
		return theColor;
	}

	/**
	 * 判断是否文本溢出
	 * @param e 事件对象
	 * @returns  返回true为未溢出  false溢出
	 */
	public static isBeyond(e: any) {
		const ev = window.event || e; // 浏览器兼容，最好写一下
		const textRange = (el: any) => {
			const textContent = el;
			const targetW = textContent.getBoundingClientRect().width;
			const range = document.createRange();
			range.setStart(textContent, 0);
			range.setEnd(textContent, textContent.childNodes.length);
			const rangeWidth = range.getBoundingClientRect().width;
			return rangeWidth > targetW;
		};
		return !textRange(ev.target);
	}
}
