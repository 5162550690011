import { store } from '@/stores';
import { userInfoInterface } from './types';
import { defineStore } from 'pinia';

export const userInfo = defineStore({
	id: 'userInfo',
	state: (): userInfoInterface => ({
		isVerify: false,
		users: {
			cca: 0,
			cid: '',
			id: '',
			name: '',
			token: '',
			isTrip: false, // 是否是携程运营商
		},
		roles: [],
		brand: {
			logo: '',
			name: '',
		},
		isSuper: false,
		items: [],
		clickNum: 0
	}),
	getters: {},
	actions: {
		setUserInfo(data: any) {
			this.users = data.user;
			this.roles = data.roles;
			this.isVerify = true;
			this.brand = data.brand;
			this.items = data.items || []

			this.isSuper = data.roles.some((r: any) => r.isSuper)
		},
	},
});

export function userInfoHook() {
	return userInfo(store);
}
