import { Utils } from './utils';

/**
 * 时间日期转换
 * @param date 当前时间，new Date() 格式
 * @param format 需要转换的时间格式字符串
 * @description format 字符串随意，如 `YYYY-mm、YYYY-mm-dd`
 * @description format 季度："YYYY-mm-dd HH:MM:SS QQQQ"
 * @description format 星期："YYYY-mm-dd HH:MM:SS WWW"
 * @description format 几周："YYYY-mm-dd HH:MM:SS ZZZ"
 * @description format 季度 + 星期 + 几周："YYYY-mm-dd HH:MM:SS WWW QQQQ ZZZ"
 * @returns 返回拼接后的时间字符串
 */
export function formatDate(date: any, format: string): string {
	if (!date) return;

	if (typeof date === 'string') {
		date = date.replace(/\-/g, '/');
		date = new Date(date);
	}

	let we = date.getDay(); // 星期
	let z = getWeek(date); // 周
	let qut = Math.floor((date.getMonth() + 3) / 3).toString(); // 季度
	const opt: { [key: string]: string } = {
		'Y+': date.getFullYear().toString(), // 年
		'm+': (date.getMonth() + 1).toString(), // 月(月份从0开始，要+1)
		'd+': date.getDate().toString(), // 日
		'H+': date.getHours().toString(), // 时
		'M+': date.getMinutes().toString(), // 分
		'S+': date.getSeconds().toString(), // 秒
		'q+': qut, // 季度
	};
	// 中文数字 (星期)
	const week: { [key: string]: string } = {
		'0': '日',
		'1': '一',
		'2': '二',
		'3': '三',
		'4': '四',
		'5': '五',
		'6': '六',
	};
	// 中文数字（季度）
	const quarter: { [key: string]: string } = {
		'1': '一',
		'2': '二',
		'3': '三',
		'4': '四',
	};
	if (/(W+)/.test(format))
		format = format.replace(RegExp.$1, RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '周' + week[we] : '周' + week[we]) : week[we]);
	if (/(Q+)/.test(format)) format = format.replace(RegExp.$1, RegExp.$1.length == 4 ? '第' + quarter[qut] + '季度' : quarter[qut]);
	if (/(Z+)/.test(format)) format = format.replace(RegExp.$1, RegExp.$1.length == 3 ? '第' + z + '周' : z + '');
	for (let k in opt) {
		let r = new RegExp('(' + k + ')').exec(format);
		// 若输入的长度不为1，则前面补零
		if (r) format = format.replace(r[1], RegExp.$1.length == 1 ? opt[k] : opt[k].padStart(RegExp.$1.length, '0'));
	}
	return format;
}

/**
 * 获取当前日期是第几周
 * @param dateTime 当前传入的日期值
 * @returns 返回第几周数字值
 */
export function getWeek(dateTime: Date): number {
	let temptTime = new Date(dateTime.getTime());
	// 周几
	let weekday = temptTime.getDay() || 7;
	// 周1+5天=周六
	temptTime.setDate(temptTime.getDate() - weekday + 1 + 5);
	let firstDay = new Date(temptTime.getFullYear(), 0, 1);
	let dayOfWeek = firstDay.getDay();
	let spendDay = 1;
	if (dayOfWeek != 0) spendDay = 7 - dayOfWeek + 1;
	firstDay = new Date(temptTime.getFullYear(), 0, 1 + spendDay);
	let d = Math.ceil((temptTime.valueOf() - firstDay.valueOf()) / 86400000);
	let result = Math.ceil(d / 7);
	return result;
}

/**
 * 将时间转换为 `几秒前`、`几分钟前`、`几小时前`、`几天前`
 */
export function formatPast(v: string) {
	if (!v || v.match(/^0001-01-01/)) {
		return '';
	}
	if (!v.match(/T/)) {
		v = v.replace(/-/g, '/');
	}

	if (v.indexOf('+0800') >= 0) {
		v = v.replace('+0800', '');
	}
	const theTime = new Date(v);
	const now = new Date();
	const y = theTime.getFullYear(); // 获取完整的年份(4位,1970-????)
	const m = theTime.getMonth() + 1; // 获取当前月份(0-11,0代表1月)
	const d = theTime.getDate(); // 获取当前日(1-31)
	const h = theTime.getHours();
	const min = theTime.getMinutes();

	if (y === now.getFullYear() && now.getTime() > theTime.getTime()) {
		const total = (now.getTime() - theTime.getTime()) / 1000;

		const day = parseInt(total / (24 * 60 * 60) + '');

		if (day === 0) {
			const hour = parseInt(total / (60 * 60) + ''); // 小时

			const afterHour = total - hour * 60 * 60; // 去掉小时后的剩余秒数
			const min = parseInt(afterHour / 60 + ''); // 分

			const afterMin = parseInt(afterHour - min * 60 + ''); // 秒

			if (hour > 0) return `${hour}小时${min}分前`;

			if (min > 0) return `${min}分前`;

			if (afterMin) return `${afterMin}秒前`;
		}
		return `${Utils.to2digit(m)}-${Utils.to2digit(d)} ${Utils.to2digit(h)}:${Utils.to2digit(min)}`;
	}

	return `${y}-${Utils.to2digit(m)}-${Utils.to2digit(d)} ${Utils.to2digit(h)}:${Utils.to2digit(min)}`;
}
/**
 * 时间问候语
 * @param param 当前时间，new Date() 格式
 * @description param 调用 `formatAxis(new Date())` 输出 `上午好`
 * @returns 返回拼接后的时间字符串
 */
export function formatAxis(param: Date): string {
	let hour: number = new Date(param).getHours();
	if (hour < 6) return '凌晨好';
	else if (hour < 9) return '早上好';
	else if (hour < 12) return '上午好';
	else if (hour < 14) return '中午好';
	else if (hour < 17) return '下午好';
	else if (hour < 19) return '傍晚好';
	else if (hour < 22) return '晚上好';
	else return '夜里好';
}

// 旧版时间格式
// 2020-01-01 20:20
export function formatDatetime(str: string | Date): string {
	if (!str) {
		return '';
	}
	let theTime: Date;
	if (typeof str === 'string') {
		str = str.replace(/\-/g, '/');
		theTime = new Date(str);
	} else {
		theTime = str;
	}
	const y = theTime.getFullYear(); // 获取完整的年份(4位,1970-????)
	const m = theTime.getMonth() + 1; // 获取当前月份(0-11,0代表1月)
	const d = theTime.getDate(); // 获取当前日(1-31)
	const h = theTime.getHours();
	const min = theTime.getMinutes();

	return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d) + ' ' + (h < 10 ? '0' + h : h) + ':' + (min < 10 ? '0' + min : min);
}

/**
 * 解析时间到字符串
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time: string | number | Date, cFormat?: string) {
	if (arguments.length === 0 || !time) {
		return null;
	}
	const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
	let date;
	if (typeof time === 'object') {
		date = time;
	} else {
		if (typeof time === 'string') {
			if (/^[0-9]+$/.test(time)) {
				// support "1548221490638"
				time = parseInt(time);
			} else {
				// support safari
				// https://stackoverflow.com/questions/4310953/invalid-date-in-safari
				time = time.replace(new RegExp(/-/gm), '/');
			}
		}

		if (typeof time === 'number' && time.toString().length === 10) {
			time = time * 1000;
		}
		date = new Date(time);
	}
	const formatObj: any = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay(),
	};
	const time_str = format.replace(/{([ymdhisa])+}/g, (result: any, key: string | number) => {
		const value = formatObj[key];
		return value.toString().padStart(2, '0');
	});
	return time_str;
}

export function formatFullDatetime(str: string | Date, type?): string {
	if (!str) {
		return '';
	}
	let theTime: Date;
	if (typeof str === 'string') {
		if (type === 'z') {
			theTime = new Date(Date.parse(str));
		} else {
			str = str.replace(/\-/g, '/');
			theTime = new Date(str);
		}
	} else {
		theTime = str;
	}
	const y = theTime.getFullYear(); // 获取完整的年份(4位,1970-????)
	const m = theTime.getMonth() + 1; // 获取当前月份(0-11,0代表1月)
	const d = theTime.getDate(); // 获取当前日(1-31)
	const h = theTime.getHours();
	const min = theTime.getMinutes();
	const second = theTime.getSeconds();

	return (
		y +
		'-' +
		(m < 10 ? '0' + m : m) +
		'-' +
		(d < 10 ? '0' + d : d) +
		' ' +
		(h < 10 ? '0' + h : h) +
		':' +
		(min < 10 ? '0' + min : min) +
		':' +
		(second < 10 ? '0' + second : second)
	);
}
/**
 * 0 =>00:00  2330=>23:30
 */

export function to2digit(n: number) {
	const s = ('000' + n).slice(-4);
	return s.substr(0, 2) + ':' + s.substr(2, 2);
}

export function getWeekDataList(data: any) {
	//根据日期获取本周周一~周日的年-月-日
	var weekList = [];
	var date = new Date(data);
	//判断本日期是否为周日，获取本周一日期
	if (date.getDay() == 0) {
		date.setDate(date.getDate() - 6);
	} else {
		date.setDate(date.getDate() - date.getDay() + 1);
	}
	var myDate: any = date.getDate();
	var myMonth: any = date.getMonth() + 1;
	if (date.getDate() < 10) {
		myDate = '0' + myDate;
	}
	if (date.getMonth() + 1 < 10) {
		myMonth = '0' + myMonth;
	}
	const dateInfo = {
		day: myDate,
		mouth: date.getFullYear() + '-' + myMonth,
		week: formatDate(date, 'WWW'),
		date: date.getFullYear() + '-' + myMonth + '-' + myDate,
	};
	weekList.push(dateInfo);
	// 获取周二以后日期
	for (var i = 0; i < 6; i++) {
		date.setDate(date.getDate() + 1);
		myDate = date.getDate();
		myMonth = date.getMonth() + 1;
		if (date.getDate() < 10) {
			myDate = '0' + myDate;
		}
		if (date.getMonth() + 1 < 10) {
			myMonth = '0' + myMonth;
		}
		const dateInfo = {
			day: myDate,
			mouth: date.getFullYear() + '-' + myMonth,
			week: formatDate(date, 'WWW'),
			date: date.getFullYear() + '-' + myMonth + '-' + myDate,
		};
		weekList.push(dateInfo);
	}
	return weekList;
}

export function getWeekNumList(data: any) {
	//根据日期获取本周周一~周日的年-月-日
	var weekList: any = {};
	var date = new Date(data);
	//判断本日期是否为周日，获取本周一日期
	if (date.getDay() == 0) {
		date.setDate(date.getDate() - 6);
	} else {
		date.setDate(date.getDate() - date.getDay() + 1);
	}
	var myDate: any = date.getDate();
	var myMonth: any = date.getMonth() + 1;
	if (date.getDate() < 10) {
		myDate = '0' + myDate;
	}
	if (date.getMonth() + 1 < 10) {
		myMonth = '0' + myMonth;
	}
	weekList[1] = date.getFullYear() + '-' + myMonth + '-' + myDate;
	// 获取周二以后日期
	for (var i = 0; i < 6; i++) {
		date.setDate(date.getDate() + 1);
		const num = date.getDay();
		myDate = date.getDate();
		myMonth = date.getMonth() + 1;
		if (date.getDate() < 10) {
			myDate = '0' + myDate;
		}
		if (date.getMonth() + 1 < 10) {
			myMonth = '0' + myMonth;
		}
		weekList[num] = date.getFullYear() + '-' + myMonth + '-' + myDate;
	}
	return weekList;
}

// 根据指定日期获取星期
export function getWeekDay(date: any) {
	let weekNum = typeof date === 'number' ? date : new Date(date).getDay();
	let week = '';
	switch (weekNum) {
		case 0:
			week = '星期天';
			break;
		case 1:
			week = '星期一';
			break;
		case 2:
			week = '星期二';
			break;
		case 3:
			week = '星期三';
			break;
		case 4:
			week = '星期四';
			break;
		case 5:
			week = '星期五';
			break;
		case 6:
			week = '星期六';
			break;
	}
	return week;
}

// 根据指定日期获取周几
export function getWeekZhouDay(date: any) {
	let weekNum = typeof date === 'number' ? date : new Date(date).getDay();
	let week = '';
	switch (weekNum) {
		case 0:
			week = '周日';
			break;
		case 1:
			week = '周一';
			break;
		case 2:
			week = '周二';
			break;
		case 3:
			week = '周三';
			break;
		case 4:
			week = '周四';
			break;
		case 5:
			week = '周五';
			break;
		case 6:
			week = '周六';
			break;
	}
	return week;
}

/**
 * 获取指定日期的前一天或者后一天
 * @param {Date} date 指定日期
 * @param {number} day 1 表示获取下一天 -1 获取前一天
 * @returns 日期
 */
export function getNextDate(date: any, day: number) {
	var dd = new Date(date);
	dd.setDate(dd.getDate() + day);
	var y = dd.getFullYear();
	var m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1;
	var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
	return y + '-' + m + '-' + d;
}

/**
 * 获取指定日期的开始时间
 * @param {Date} date 指定日期
 * @param {number} day 0 代表当日00.00.00 -1代表前一天00.00.00
 * @returns 日期
 */
export function getTimeStart(time?: Date, day: number = 0, Format: string = ' +0800') {
	let date = time ? time : new Date();
	return formatFullDatetime(new Date(new Date(date.toLocaleDateString()).getTime() + day * 24 * 60 * 60 * 1000)) + Format;
}

/**
 * 获取指定日期的结束时间
 * @param {Date} date 指定日期
 * @param {number} day 1 代表当日23.59.59 0代表前一天23.59.59
 * @returns 日期
 */
export function getTimeEnd(time?: Date, day: number = 1, Format: string = ' +0800') {
	let date = time ? time : new Date();
	return formatFullDatetime(new Date(new Date(date.toLocaleDateString()).getTime() + day * 24 * 60 * 60 * 1000 - 1)) + Format;
}

export function getYear(time?: Date, num: number = 0) {
	let date = time ? new Date(time) : new Date();
	date.setDate(date.getDate());
	let y = date.getFullYear() + num;
	return y + '-01-01';
}

export function getMonth(time?: Date, num: number = 0) {
	let date = time ? new Date(time) : new Date();
	date.setDate(date.getDate());
	let y = date.getFullYear();
	let m: any = date.getMonth() + 1 + num;
	if (m < 10) m = `0${m}`;
	if (m > 12) {
		y = y + 1;
		m = m - 12;
	}
	return y + '-' + m + '-01';
}

export function getDay(time?: Date, num: number = 0) {
	let date = time ? new Date(time) : new Date();
	date.setDate(date.getDate() + num);
	let y = date.getFullYear();
	let m: any = date.getMonth() + 1;
	let d: any = date.getDate();
	if (m < 10) m = `0${m}`;
	if (d < 10) d = `0${d}`;
	return y + '-' + m + '-' + d;
}

// 获取某个月后的当前日期
export function getNextMonthToday(time?: Date, num: number = 1) {
	let today = time ? new Date(time) : new Date();
	let nextMonthToday = new Date(today.getFullYear(), today.getMonth() + num, today.getDate());
	return nextMonthToday;
}

// 获取指定N天后的日期
export function getAppointDate(time, num: number = 1, format = 'YYYY-mm-dd') {
	const curDate = new Date(time);

	const date = new Date(curDate.getTime() + 24 * 60 * 60 * 1000 * num);

	return formatDate(date, format);
}

// 判断两个时间的大小
export function getCompareToday(oneDay, twoDay) {
	const today = new Date(oneDay).getTime();

	const date = new Date(twoDay).getTime();

	return today > date;
}

// 获取近期的前n个月
export function getRecentMonth(n) {
	let dataArr = [];
	let data = new Date();
	data.setMonth(data.getMonth() + 1, 1);
	for (let i = 0; i < n; i++) {
		data.setMonth(data.getMonth() - 1);
		let m: any = data.getMonth() + 1;
		m = m < 10 ? '0' + m : m;
		dataArr.push(data.getFullYear() + '-' + m);
	}
	return dataArr.reverse();
}

// 计时两个时间相差的天数
export function getDaysBetween(dateString1, dateString2) {
	var startDate = Date.parse(dateString1);
	var endDate = Date.parse(dateString2);
	var days = (startDate - endDate) / (1 * 24 * 60 * 60 * 1000);
	return days;
}

// 获取当前时间的n个小时之后
export function getCurrentTimeAfter(n) {
	var date = new Date();
	var date1 = new Date().getTime(); // 获取当前时间戳
	// 当前时间戳+3600s（一小时，其他时间通过计算时间戳进行相应加减），重新设置 Date 对象
	date.setTime(date1 + n * 3600000);
	return date1 + n * 3600000;
}

// 判断是否是今天
export function isToday(dateString) {
	const inputDate = new Date(dateString);
	const today = new Date();

	// 确保两个日期都在同一时区  
	inputDate.setHours(0, 0, 0, 0);
	today.setHours(0, 0, 0, 0);

	// 比较年、月、日是否相同  
	// 注意：getMonth()返回的月份是从0开始的，所以我们需要加1来得到通常的月份表示  
	return (
		inputDate.getFullYear() === today.getFullYear() &&
		(inputDate.getMonth() + 1) === (today.getMonth() + 1) &&
		inputDate.getDate() === today.getDate()
	);
}


export function dateToNumber(date: any) {
	let newDate: number;
	newDate = parseInt(date.replace(/-/g, ''));
	return newDate;
}

export function hourToNumber(hour: string) {
	let newHour: number;
	newHour = parseInt(hour.replace(/:/g, ''));
	return newHour;
}