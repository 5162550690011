<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QiSelect',
})
</script>
<template>
	<el-select
		v-model="selectVal"
		:multiple="isArray(formItem.initVal)"
		:filterable="formItem.isSearch"
		:remote="formItem.isSearch"
		:placeholder="placeholder"
		:remote-method="remoteMethod"
		:loading="formItem.isSearch && loading"
		:disabled="formItem.isDisabled"
		:collapse-tags="formItem.collapseTags"
		:collapse-tags-tooltip="formItem.collapseTags"
		@change="changeEvent"
		@blur="blur"
		@focus="focus"
		clearable
	>
		<el-option v-for="item in selectOptions" :key="item.value" :label="item.label" :value="item.value">
			<span style="float: left">{{ item.label }}</span>
			<span style="float: right; font-size: 13px" v-if="formItem.isExtend">{{ item.extendName }}</span>
			<span style="float: right; font-size: 13px" v-if="formItem.isTips">{{ item.tips }}</span>
		</el-option>
	</el-select>
</template>

<script name="QiSelect" setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import utils from '@/utils/index';
import { Utils } from '@/utils/utils';

const emit = defineEmits(['update:modelValue', 'blur', 'focus']);

const props = defineProps({
	ruleForm: {
		type: Object,
		default: () => {},
	},
	formItem: {
		type: Object,
		default: () => {},
	},
	isRest: {
		type: Boolean,
		default: false,
	},
	modelValue: {
		type: [String, Number, Object],
		default: '',
	},
});

const selectVal: any = ref('');
const loading = ref(false);
const selectOptions: any = ref([]);

const isArray = (initVal: any) => {
	return utils.isArr(initVal);
};

const placeholder = computed(() => {
	const item = props.formItem;
	return item.placeholder ? item.placeholder : `请选择${item.label}`;
});

const options = computed(() => {
	if (props.formItem.options) {
		return props.formItem.options;
	} else {
		return [];
	}
});

onMounted(() => {
	selectOptionsFun();
});

const selectOptionsFun = () => {
	// console.log("selectOptionsFun", props.formItem);
	const { options, urlOptions, initVal, isInitLabel, value } = props.formItem;
	if (initVal) selectVal.value = initVal;
	if (value) selectVal.value = value;
	if (!options && !urlOptions) {
		throw new ReferenceError('options or urlOptions" does not exist.open fn.js and add it');
	}
	if (isInitLabel) initLabel();
	if (options) {
		selectOptions.value = options;
	} else {
		selectOptionNext();
	}
};

const selectOptionNext = () => {};

const remoteMethod = ($query: any) => {
	if ($query) {
		// console.log($query);
		const { urlOptions } = props.formItem;
		// console.log(props.formItem, urlOptions);
		const params = Utils.deepCopy(urlOptions.params);
		if (urlOptions.searchKey) {
			Object.assign(params, { [urlOptions.searchKey]: $query.trim() });
		}

		loading.value = true;
		urlOptions.url(params || {}).then((res: any) => {
			loading.value = false;
			if (utils.isFunction(urlOptions.formatRes)) res = urlOptions.formatRes(res);
			if (utils.isArr(res)) {
				selectOptions.value = res.map((item: any) => {
					item.value = item[urlOptions.keyName];
					item.label = item[urlOptions.valueName];
					item.extendName = item[urlOptions.extendName];
					if (props.formItem.isTips && props.formItem.cardType) item.tips = getCardTip(item);
					return item;
				});
			} else {
				selectOptions.value = [];
			}
		});
	} else {
		selectOptions.value = [];
	}
};

const initialize = ref(false);

const initLabel = () => {
	if (initialize.value) {
		return;
	}
	initialize.value = true;
	const { urlOptions } = props.formItem;
	// console.log(props.formItem, urlOptions);
	const params = Utils.deepCopy(urlOptions.params);
	// 回显不做数量限制
	if (params.limit) delete params.limit;
	// 默认keyName为搜索key，自定义initSearchKey
	if (urlOptions.initSearchKey || urlOptions.keyName) {
		Object.assign(params, {
			[urlOptions.initSearchKey || urlOptions.keyName]: isArray(selectVal.value) ? selectVal.value.join(',') : selectVal.value,
		});
	}
	urlOptions.url(params || {}).then((res: any) => {
		if (utils.isFunction(urlOptions.formatRes)) res = urlOptions.formatRes(res);
		if (utils.isArr(res)) {
			selectOptions.value = res.map((item: any) => {
				item.value = item[urlOptions.keyName];
				item.label = item[urlOptions.valueName];
				if (props.formItem.isTips && props.formItem.cardType) item.tips = getCardTip(item);
				return item;
			});
			selectVal.value = props.modelValue || props.formItem.initVal;
		} else {
			selectOptions.value = [];
		}
		initialize.value = false;
	});
};

// 卡的描述
const getCardTip = (card: any) => {
	let tips = '';
	switch (props.formItem.cardType) {
		case 5:
			tips = card.duration + (card.type == 1 ? '个月，' : '天，');
			break;
		case 24:
			tips = Utils.formatToyuan(card.fees[0].price) + '元，' + (card.duration > 0 ? card.duration + '个月过期，' : '长期，');
			break;
		case 26:
			tips = card.counter + '次，';
			break;
		case 27:
			tips = card.duration + '个月过期，';
			break;
		case 48:
			tips = card.duration + '个月，';
			break;
	}

	// 1代表三方核销中次票
	tips = props.formItem.cardType == 1 ? tips + (card.state == 2 ? '上架' : '下架') : tips + (card.state == 1 ? '上架' : '下架');
	return tips;
};

const changeEvent = () => {
	let checked: any = selectVal.value;
	// 返回对象形式的
	if (props.formItem.isObject && selectOptions.value?.length > 0) {
		checked = selectOptions.value?.filter(($item: any) => {
			if ($item.value === selectVal.value) {
				return $item;
			}
		})[0];
	}
	if (utils.isString(checked) && !checked) checked = undefined;
	// eslint-disable-next-line vue/no-mutating-props
	if (props.formItem.key) props.ruleForm[props.formItem.key] = checked;
	emit('update:modelValue', checked);
};

watch(
	options,
	() => {
		selectOptionsFun();
	},
	{ deep: true }
);

watch(
	() => props.isRest,
	() => {
		if (props.isRest) {
			selectVal.value = props.formItem.initVal;
		}
	}
);

watch(
	() => props.formItem.value,
	() => {
		selectVal.value = props.formItem.value;
		changeEvent();
	}
);

watch(
	() => props.modelValue,
	() => {
		selectVal.value = props.modelValue;
		// 回显首次请求
		if (selectVal.value && selectVal.value.length > 0 && selectOptions.value.length == 0) {
			initLabel();
		}
	},
	{ immediate: true }
);

const blur = () => {
	emit('blur');
};
const focus = () => {
	emit('focus');
};
</script>

<style lang="scss" scoped></style>
